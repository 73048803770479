.image-frame {
  position: relative;

  &-text {
    font-size: 14px;
    font-weight: normal;
    inline-size: 350px;
    inset-block-end: 37px;
    inset-inline-start: 37px;
    line-height: 22px;
    margin: 0;
    position: absolute;
    text-align: start;

    h2 {
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      text-align: start;

      span {
        background-color: #fff;
        display: inline-block;
        padding: 0 8px;
      }
    }
  }
}
