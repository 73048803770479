.item-enter {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.item-enter-active {
  opacity: 0.9;
  transition: opacity 500ms ease-in-out;
}

.item-exit {
  opacity: 0.9;
  transition: opacity 500ms ease-in-out;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
