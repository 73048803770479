@import "mixins";
@import "custom";
@import "variables";

.module-text {
  margin: 10px 0;
  padding: 0;

  &.black {
    color: $white;
  }

  &.white {
    color: $black;
  }

  &-inner {
    inset-inline-end: 0;
    padding: 0 0 20px;
    position: relative;

    @media only screen and (width >= 1450px) {
      inset-inline-end: -20px;
    }

    @include media-breakpoint-down(md) {
      inset-inline-end: 0;
    }

    &-added-border {
      border-block-start: 1px solid $gray-light;
      padding-block-start: 25px;
    }
  }

  @include media-breakpoint-down(md) {
    margin: 0;
    padding: 4rem 0;
  }

  .text-left {
    text-align: start;
  }

  .text-right {
    text-align: end;
  }

  .text-center {
    text-align: center;
  }
}

.editorial-title {
  padding: 0;

  @include media-breakpoint-down(md) {
    margin-block-end: 0;
    padding: 0;
  }

  @include media-breakpoint-down(sm) {
    max-inline-size: none !important;
  }
}

.join-flow-cta {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: bold;
  padding: 10px 25px;
}
