@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.module-editorial-grid-with-description-card {
  color: colors.$gray;
  margin-block-end: 100px;

  img {
    inline-size: 100%;
  }
}

.module-editorial-grid-with-description-card-mobile {
  background-color: transparent !important;
  color: colors.$black;
  margin-block-end: 0;

  @include media-breakpoint-down(sm) {
    color: colors.$white;
  }

  img {
    inline-size: 100%;
  }
}

.description-card-body {
  background-color: colors.$white;
  block-size: 295px;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    h3 {
      color: colors.$white;
    }

    background-color: transparent;
    block-size: auto;
    color: colors.$white;
    padding-block-start: 20px;
  }
}

.module-editorial-grid-with-description-media {
  margin-block-end: -142px;
  padding-block-end: 133.33%;

  @include media-breakpoint-down(md) {
    margin-block-end: -194px;
  }
}

.module-editorial-grid-with-description-img {
  object-fit: cover;
}

.cta-link {
  block-size: 24px;
  color: colors.$white;
  line-height: 1.71;
  text-decoration: underline;
}

.card-block-margin {
  margin: 31px 24px;
}

.module-editorial-grid-with-description-headline {
  @include typography.small-headline;

  color: colors.$white;

  @include media-breakpoint-up(md) {
    color: colors.$black;
    margin-block-end: 8px;
  }
}

.module-editorial-grid-with-description-tag {
  @include typography.eyebrow;

  background-color: colors.$black;
  color: colors.$white;
  padding: 4px 8px;
}

.module-editorial-grid-withdescription-tertiary-copy {
  margin-block-start: 8px;
}

.non-active-slide {
  opacity: 0.5;
  transform: scale(0.8);
}

.active-slide {
  transform: scale(1);
}

.slide-animation {
  transform-origin: top left;
  transition: all 500ms ease-in-out;
}
