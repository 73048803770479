@use "breakpoints";
@import "mixins";
@import "custom";

.on-demand {
  // TODO remove noTopMargin when Services module is created and replaced this module
  min-block-size: 70vh;
  overflow: hidden;
  padding: 4rem 0;
  position: relative;

  .on-demand-inner {
    opacity: 0.2;
    transition: opacity 30ms;

    @include media-breakpoint-down(md) {
      max-inline-size: none !important;
    }
  }

  .slider-image {
    padding-inline-end: 25px;
  }

  .slider-wrapper {
    inset-block-start: 30px;
    inset-inline-end: -20px;
    position: absolute;

    @include media-breakpoint-down(md) {
      inset-block-start: 0;
      position: relative;
    }

    @include media-breakpoint-up(xl) {
      inline-size: 60%;
    }

    .editorial-single-column-card {
      padding: 0 0 50px;

      img {
        inline-size: 100%;
        margin-inline-end: 20px;
        max-inline-size: none;
      }
    }

    .gallery-controls-container {
      display: flex;
      inset-inline-end: 15%;
      justify-content: flex-end;
      position: absolute;
      transition: all 300ms;
      z-index: 1;

      button {
        /* Corrects inability to style clickable `input` types in iOS */
        appearance: none;
        border: none;

        /* inherit font & color from ancestor */
        color: $black;

        /* Corrects font smoothing for webkit */
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        inline-size: auto;

        /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
        line-height: normal;
        margin: 0;
        overflow: visible;
        padding: 0;

        @include white-nav-button-states;
      }

      .left-button {
        margin-inline-end: 4px;

        .left-arrow {
          background: $white;

          g {
            fill: $black;
          }
        }

        &.disabled-button {
          g {
            fill: $gray;
          }
        }
      }

      .right-button {
        margin-inline-start: 4px;

        .right-arrow {
          background: $white;
          transform: rotate(180deg);

          g {
            fill: $black;
          }
        }

        &.disabled-button {
          g {
            fill: $gray;
          }
        }
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

.on-demand [data-first-non-landing-module="true"] {
  @media (width >= breakpoints.$lg) {
    .on-demand-inner {
      opacity: 1;
    }
  }
}

.editorial-title {
  color: $black;

  @include media-breakpoint-down(sm) {
    padding: 0 24px;
  }
}
