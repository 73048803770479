.empty-image-container {
  img {
    inline-size: 100%;
  }
}

.video-background {
  inline-size: 100%;
  position: relative;
  z-index: 8;

  [class*="video-player_"] {
    block-size: 100%;
    inline-size: 100%;
  }
}
