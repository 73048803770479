@use "breakpoints";
@use "colors";

.intent-classification-modal {
  background-size: cover;
  border-radius: 15px;
  max-inline-size: 640px;
  overflow: hidden;
  padding: 10px 15px 25px;
  position: relative;

  @media (width >= breakpoints.$md) {
    padding: 10px 25px 25px;
  }

  &-header {
    color: colors.$white;
    margin-block: 20px 30px;
    padding: 55px 0 30px;
    position: relative;
    text-align: center;

    h2 {
      font-size: 66px;
      line-height: 1.1em;
      margin: 0 auto 20px;
      text-transform: uppercase;
    }

    p {
      font-size: 16px;
      letter-spacing: 3px;
      margin: 0 auto;
      max-inline-size: none;
      text-transform: uppercase;

      @media (width >= breakpoints.$md) {
        font-size: 20px;
        max-inline-size: 70%;
      }
    }
  }

  &-close {
    background-color: transparent;
    border: 1px solid transparent;
    inset-block-start: 10px;
    inset-inline-end: 10px;
    margin: 0;
    outline: none;
    padding: 0;
    position: absolute;
    z-index: 9999;

    &:active,
    &:focus {
      border: 1px solid #000;
      outline: none;
    }
  }

  &-button {
    margin: 10px 0;
  }

  &-options {
    color: colors.$white;
    column-gap: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    inline-size: 100%;
    margin: 0;
    padding: 20px 10px;

    @media (width >= breakpoints.$sm) {
      min-inline-size: 520px;
    }

    @media (width >= breakpoints.$md) {
      margin: 0 10px;
      padding: 20px 40px;
    }

    a {
      border: none;
      margin: 10px 0;
      outline: none;

      > div {
        border: 1px solid colors.$white;
        padding: 12px 6px;
      }

      &:focus,
      &:active {
        border: 4px double colors.$white;

        > div {
          border: none;
          padding: 8px 2px;
        }
      }
    }
  }
}
