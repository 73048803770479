@import "mixins";
@import "custom";

.editorial-single-column {
  // margin: 10vh 0 30vh 0;
  overflow: hidden;
  padding: 50px 0;
  position: relative;

  @include media-breakpoint-down(md) {
    margin: 80px 0;
  }

  &-container {
    margin: 0 auto;
    padding: 0;

    @include media-breakpoint-down(md) {
      max-inline-size: none !important;
    }
  }

  &-card {
    margin: 65px 0;
    max-inline-size: 356px;

    @include media-breakpoint-down(md) {
      margin: 0 !important;
      max-inline-size: calc(100% - 48px);
    }

    img {
      max-inline-size: 100%;
    }

    &:first-of-type {
      margin-block-start: 250px;
    }

    &:last-of-type {
      margin-block-end: 160px;
    }
  }

  &-top {
    margin: 0 auto;

    @include media-breakpoint-down(md) {
      margin: 64px 0 0;
    }
  }

  &-bottom {
    block-size: calc(100vh + 120px);
    color: $white;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    margin: 0 auto;
    position: absolute;
    text-align: start;

    @include media-breakpoint-down(md) {
      block-size: auto;
    }

    &-text {
      margin: 10px 0 0;
      position: relative;
    }

    &-image {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    > img {
      block-size: 100vh;
    }

    h2 {
      font-size: 60px;
      line-height: 60px;
      margin: 0 0 24px;
    }

    p {
      font-size: 16px;
      line-height: 30px;
    }
  }
}
