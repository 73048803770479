@import "variables";
@import "accessibility";

@mixin bg-image-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin fade-transition {
  .fade {
    $duration: 400ms;

    &-enter {
      opacity: 0;
      transition: $duration;
    }

    &-enterActive {
      inset-block-start: 0;
      inset-inline-start: 0;
      opacity: 1;
      position: absolute !important;
      transition: $duration;
    }

    &-exit {
      opacity: 1;
      transition: $duration;
    }

    &-exitActive {
      opacity: 0;
      transition: $duration;
    }
  }
}

@mixin right-crop {
  .right-crop {
    $duration: 1000ms;

    &-enter {
      position: absolute !important;
      transform: scale(1.1);
      z-index: 10;
    }

    &-enterActive {
      position: absolute;
      transform: scale(1);
      transition:
        clip-path $duration ease-in-out,
        transform $duration ease-in-out;
    }

    &-enterDone {
      position: relative;
    }

    &-exit {
      clip-path: inset(0 0 0 0);
      transform: scale(1);
      z-index: 11;
    }

    &-exitActive {
      clip-path: inset(0 1800px 0 0);
      position: absolute !important;
      transform: scale(1.1);
      transition:
        clip-path $duration ease-in-out,
        transform $duration ease-in-out;
    }
  }
}

@mixin right-crop-no-scale {
  .right-crop-no-scale {
    $duration: 400ms;

    &-enter {
      position: absolute !important;
      z-index: 10;
    }

    &-enterActive {
      position: absolute;
      transition: clip-path $duration ease-in-out;
    }

    &-enterDone {
      position: relative;
    }

    &-exit {
      clip-path: inset(0 0 0 0);
      z-index: 11;
    }

    &-exitActive {
      clip-path: inset(0 1800px 0 0);
      position: absolute !important;
      transition: clip-path $duration ease-in-out;
    }
  }
}

@mixin left-crop {
  .left-crop {
    $duration: 1000ms;

    &-enter {
      position: absolute !important;
      transform: scale(1.1);
      z-index: 10;
    }

    &-enterActive {
      position: absolute;
      transform: scale(1);
      transition:
        clip-path $duration ease-in-out,
        transform $duration ease-in-out;
    }

    &-enterDone {
      position: relative;
    }

    &-exit {
      clip-path: inset(0 0 0 0);
      z-index: 11;
    }

    &-exitActive {
      clip-path: inset(0 0 0 1800px);
      position: absolute !important;
      transition:
        clip-path $duration ease-in-out,
        transform $duration ease-in-out;
    }
  }
}

@mixin left-crop-no-scale {
  .left-crop-no-scale {
    $duration: 400ms;

    &-enter {
      position: absolute !important;
      z-index: 10;
    }

    &-enterActive {
      position: absolute;
      transition: clip-path $duration ease-in-out;
    }

    &-enterDone {
      position: relative;
    }

    &-exit {
      clip-path: inset(0 0 0 0);
      z-index: 11;
    }

    &-exitActive {
      clip-path: inset(0 0 0 1800px);
      position: absolute !important;
      transition: clip-path $duration ease-in-out;
    }
  }
}

// Definitely think all these CSS keyframe animations should go in a global / accessible place...
// To do: Figure out that place.
@keyframes spin-element {
  100% {
    transform: rotate(360deg);
  }
}

@mixin spinner-rotate-animation {
  animation: spin-element 2s linear infinite;
}

// Breakout of container
@mixin breakout-container {
  inset-inline-start: calc(-1 * (100vw - 100%) / 2);
  min-inline-size: 100vw;
  position: relative;
}
