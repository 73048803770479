@use "colors";

@mixin primary-button-black-states {
  outline: none !important;

  &:active {
    border: 1px solid colors.$white;
  }

  &:focus {
    border: 4px double colors.$white;
  }
}

@mixin secondary-button-white-states {
  outline: none !important;

  &:active {
    border: 1px solid $black;
  }

  &:focus {
    border: 4px double $black;
  }
}

@mixin white-nav-button-states {
  border: 4px double transparent;
  outline: none !important;

  &:active {
    border: 4px double transparent;
  }

  &:focus {
    border: 4px double $black;
  }
}

@mixin text-button-states-black-border {
  inset-inline-start: -6px;
  outline: none !important;
  padding: 2px 3px;
  position: relative;

  &:active {
    border: 1px solid transparent;
  }

  &:focus {
    border: 1px solid $black;
    text-decoration: none !important;
  }
}

@mixin text-button-states-white-border {
  inset-inline-start: -4px;
  outline: none !important;
  padding: 4px 6px;
  position: relative;

  &:active {
    border: 1px solid transparent;
  }

  &:focus {
    border: 1px solid $white;
    text-decoration: none !important;
  }
}
