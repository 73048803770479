@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.gallery-pager {
  $key-transition-time: 1000ms;
  $component-name: gallery-pager;

  &-item {
    color: colors.$white;
    cursor: pointer !important;
    margin: 0;
    outline: none;
    padding: 15px 18px 18px;
    position: relative;
    transition:
      color $key-transition-time ease-in-out,
      width $key-transition-time ease-in-out,
      height $key-transition-time ease-in-out,
      background-color $key-transition-time ease-in-out;

    @include media-breakpoint-down(md) {
      background-color: transparent;
      border-inline-end-color: transparent;
      transition: none;
    }

    &-description-wrap {
      @include typography.body-small;

      margin: 10px 0;
      min-block-size: 70px;
      overflow: hidden;
      position: relative;

      p {
        color: colors.$gray;
        margin: 0;
      }
    }

    &-single {
      cursor: default;
      .#{$component-name}-item-progress {
        background-color: colors.$medium-gray;
        inset-inline-start: 0;

        span {
          background-color: transparent !important;
        }
      }
      .#{$component-name}-item-background-shim {
        background-color: transparent !important;
      }
    }

    &:first-of-type {
      .#{$component-name}-item-border-shim {
        display: none;
      }
      .#{$component-name}-item-background-shim {
        display: block;
        inset-inline-end: 100%;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    &:last-of-type {
      border-inline-end: none;
      .#{$component-name}-item-background-shim {
        display: block;
        inset-inline-start: 100%;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    &-active {
      background-color: colors.$white;
      color: colors.$black;

      @include media-breakpoint-up(lg) {
        .gallery-pager-item-description-wrap {
          p {
            color: colors.$off-black;
          }
        }
      }

      @include media-breakpoint-down(md) {
        background-color: transparent;
        color: colors.$white;
        transition-delay: 300ms;
      }

      .#{$component-name}-item-background-shim {
        opacity: 1;
      }
      .#{$component-name}-item-progress {
        span {
          background-color: colors.$black;
        }
      }
    }

    h3 {
      margin: 0;
      white-space: nowrap;

      span {
        position: relative;
      }
    }

    &-play-toggle-wrap {
      inset-block-start: 10px;
      position: relative;
    }

    &-progress {
      inline-size: 100%;
      inset-block-end: 0;
      inset-inline-start: 0;
      position: absolute;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-inner {
      display: block;
      min-block-size: 68px;
      transition: height 700ms ease-in-out;

      h3 {
        transition: height 700ms ease-in-out;

        span {
          transition: height 700ms ease-in-out;
        }
      }
    }

    &-number {
      @include typography.body;

      block-size: 24px;
      margin: 0;
      opacity: 1;
      overflow: hidden;
      transition:
        height 700ms ease-in-out,
        opacity 700ms ease-in-out;
      transition-delay: 500ms;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-title {
      @include typography.subhead-regular;
    }

    &-border-shim {
      background-color: colors.$white;
      block-size: calc(100% - 6px);
      inline-size: 1px;
      inset-block-end: 6px;
      position: absolute;

      &-left {
        inset-inline-start: 0;
      }

      &-right {
        inset-inline-end: 0;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-background-shim {
      background-color: colors.$white;
      block-size: calc(100% - 6px);
      display: none;
      inline-size: 1000px;
      inset-block-end: 6px;
      inset-inline-end: 100%;
      opacity: 0;
      position: absolute;
      transition: opacity $key-transition-time ease-in-out;
    }
  }
}
