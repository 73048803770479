@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.video-player {
  overflow: hidden;
  position: relative;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    outline: none;
    padding: 0;

    svg {
      display: block;
      margin: 0;
      padding: 0;
    }
  }

  &-progress {
    background-color: rgb(255 255 255 / 50%);
    block-size: 2px;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 400ms ease-in-out;
    z-index: 15;

    &-active {
      opacity: 1;
    }

    > span {
      background-color: colors.$white;
      block-size: 2px;
      display: block;
      inline-size: 0%;
      transition: width 250ms ease;
    }
  }

  &-controls {
    inline-size: 100%;
    inset-block-end: 0;
    inset-inline-start: 0;
    position: absolute;
    z-index: 13;

    &-title {
      h2 {
        font-size: 14px;
        font-weight: normal;
        inline-size: 350px;
        inset-block-start: 0;
        inset-inline-start: calc(100% + 5px);
        line-height: 22px;
        margin: 0;
        position: absolute;
        text-align: start;

        div {
          margin-block-end: 4px;

          span {
            background-color: colors.$white;
            display: inline-block;
            padding: 0 8px;
          }
        }
      }
    }

    &-mute-toggle {
      inset-block-end: 37px;
      inset-inline-end: 26px;
      position: absolute;

      button {
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none !important;
        padding: 8px 7px;

        svg {
          block-size: 16px;
          inline-size: 20px;
        }
      }
    }

    &-play-toggle {
      inset-block-end: 24px;
      inset-inline-start: 24px;
      position: absolute;

      button {
        background-color: colors.$black;
        border: 4px solid colors.$black;
        outline: none !important;
      }
    }

    &-play-toggle button:focus {
      border: 4px double colors.$white;
    }

    &-mute-toggle button:focus {
      border: 1px solid colors.$white;
    }
  }

  &-poster {
    block-size: 100%;
    cursor: pointer;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 1;
    position: absolute;
    transition: opacity 700ms ease;
    z-index: 11;

    img {
      block-size: 100%;
      inline-size: 100%;
      margin: 0;
      object-fit: cover;

      // display: block;
    }

    &-hidden {
      opacity: 0;
    }
  }

  &-object {
    inset-block-start: 0;
    margin: 0;
    position: absolute;
    z-index: 12;

    video {
      block-size: 100%;
      display: block;
      margin: 0;
      object-fit: cover;
      position: relative;
    }
  }
}

.module-editorial-grid-single-column-url-ref {
  :hover {
    color: colors.$white;
  }
}

.module-editorial-grid-single-column-title {
  @include typography.small-headline;

  color: colors.$white;
  margin-block-end: 8px;

  @include media-breakpoint-down(md) {
    margin-block-end: 10px;
  }
}

.module-editorial-grid-single-column-subcopy {
  color: colors.$gray;
}
