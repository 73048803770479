@use "colors";
@use "typography";
@import "custom";

.elevated-experience {
  margin: 40px auto 30px;

  @include media-breakpoint-up(md) {
    margin: 140px auto 30px;
  }

  .header {
    @include typography.medium-headline;

    color: colors.$white;
    margin: 0 0 30px;
    max-inline-size: 140px;

    @include media-breakpoint-up(md) {
      margin-block-end: 0;
    }
  }

  .card {
    background-color: colors.$black;
    margin-block-start: 66px;
  }
}
