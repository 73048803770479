@import "mixins";
@import "custom";

.close-button {
  background-color: transparent;
  border: 1px solid transparent;
  inset-block-start: -20px;
  inset-inline-end: -80px;
  margin: 0;
  outline: none !important;
  padding: 3px;
  position: absolute;
  z-index: 999;

  @include media-breakpoint-down(lg) {
    inset-block-start: -43px;
    inset-inline-end: 0;
  }

  &:focus {
    border: 1px solid $white;
  }
}
