@import "mixins";
@import "custom";

.with-description {
  margin: 0;
  overflow: hidden;
  padding: 0 0 100px;

  // TODO remove top margin when above servises overlap issue is fixed
  position: relative;

  @include media-breakpoint-down(lg-2) {
    min-block-size: 700px !important;
  }

  @include media-breakpoint-down(qhd) {
    min-block-size: 640px !important;
  }

  @include media-breakpoint-down(wqhd) {
    min-block-size: 850px;
  }

  @include media-breakpoint-up(wqhd) {
    min-block-size: 1000px !important;
  }

  @include media-breakpoint-down(md) {
    inline-size: 100%;
    margin: 0;
    padding: 4rem 0;
  }

  .with-description-inner {
    @include media-breakpoint-down(md) {
      max-inline-size: none !important;
    }
  }

  .card-description-content {
    display: flex;
    padding-inline-start: 67px;

    @include media-breakpoint-down(md) {
      padding-inline-start: 67px;
    }

    @media only screen and (device-width >= 654px) and (device-width <= 1366px) {
      padding-inline-start: 67px;
    }

    @media only screen and (device-width >= 1440px) {
      padding-inline-start: 82px;
    }

    @media only screen and (device-width >= 1920px) {
      padding-inline-start: 92px;
    }

    @media only screen and (device-width <= 1280px) {
      padding-inline-start: 55px;
    }
  }

  .slider-wrapper {
    inset-block-start: 0;
    inset-inline-end: -20px;
    position: absolute;

    @include media-breakpoint-down(md) {
      margin-block-start: 20px;
      position: relative;
    }

    @include media-breakpoint-up(xl) {
      inline-size: 60%;
    }

    .editorial-single-column-card {
      padding: 0 12px 50px;
    }

    .with-description-card-gallery-controls-container {
      display: flex;
      inset-block-end: 561px;
      inset-inline-end: 17%;
      justify-content: flex-end;
      position: absolute;

      button {
        /* Corrects inability to style clickable `input` types in iOS */
        appearance: none;
        border: none;

        /* inherit font & color from ancestor */
        color: $black;

        /* Corrects font smoothing for webkit */
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        inline-size: auto;

        /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
        line-height: normal;
        margin: 0;
        overflow: visible;
        padding: 0;
        z-index: 1;

        @include white-nav-button-states;
      }

      .left-button {
        margin-inline-end: 4px;

        .left-arrow {
          background: $white;

          g {
            fill: $black;
          }
        }

        &.disabled-button {
          g {
            fill: $gray;
          }
        }
      }

      .right-button {
        margin-inline-start: 4px;

        .right-arrow {
          background: $white;
          transform: rotate(180deg);

          g {
            fill: $black;
          }
        }

        &.disabled-button {
          g {
            fill: $gray;
          }
        }
      }

      @include media-breakpoint-down(md) {
        display: none;
      }

      @include media-breakpoint-down(lg) {
        inset-block-end: 514px;
      }

      @media only screen and (device-width >= 654px) and (device-width <= 1366px) {
        inset-block-end: 496px;
      }

      @media only screen and (device-width <= 1280px) {
        inset-block-end: 482px;
      }
    }
  }
}
