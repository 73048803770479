@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.breaker {
  margin: 0;
  opacity: 0;
  position: relative;
  transition: opacity 1000ms;
  user-select: none;

  &-loaded {
    opacity: 1;
  }

  &-container {
    block-size: calc(100vh - 96px);
    inline-size: 100%;
    position: relative;
  }

  &-lower-spacer {
    block-size: 20vh;

    @include media-breakpoint-down(md) {
      block-size: 10vh;
    }
  }

  &-slide {
    block-size: 100vh;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    max-block-size: 100vh;
    overflow: hidden;
    position: absolute;

    &-inner {
      block-size: 90vh;
      inset-inline-start: 50%;
      position: absolute;
      transform: translateX(-50%);
      z-index: 15;

      @include media-breakpoint-down(md) {
        block-size: 68vh;
      }

      @include media-breakpoint-down(sm) {
        block-size: 80vh;
      }
    }

    &-controls {
      inset-block-end: 70px;
      inset-inline-end: 12px;
      position: absolute;
      z-index: 20;

      @include media-breakpoint-down(md) {
        inset-inline-end: 24px;
      }

      &-prev,
      &-next {
        background-color: colors.$white;
        border: none;
        outline: none !important;
        padding: 0;
      }

      &-prev {
        margin-inline-end: 4px;
        position: relative;
      }

      &-next {
        position: relative;

        svg {
          transform: rotate(180deg);
        }
      }
    }

    &-mobile-image {
      display: none;

      // height: 100%;
      @include media-breakpoint-down(md) {
        display: block;
      }
    }

    &-desktop-image {
      // height: 100%;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-headline {
      color: colors.$white;
      inset-block-start: 170px;
      inset-inline-start: 12px;
      padding-inline: 0;
      position: absolute;

      @include media-breakpoint-down(lg) {
        inset-inline-start: 12px;
      }

      @include media-breakpoint-down(md) {
        inset-inline-start: 0;
      }

      h2 {
        @include typography.medium-headline;

        margin: 0 0 20px;
        text-align: start;

        @include media-breakpoint-down(sm) {
          margin: 0 0 16px;
        }
      }

      ul {
        list-style: none;
        margin: 42px 0 0;
        padding: 0;
      }
    }

    &-sub-head {
      inset-block-end: 70px;
      inset-inline-start: 12px;
      position: absolute;

      @include media-breakpoint-down(lg) {
        inset-inline-start: 18px;
      }

      @include media-breakpoint-down(md) {
        inset-inline-start: 24px;
      }

      h3 {
        @include typography.eyebrow;

        color: colors.$black;
        margin: 0;
        text-align: start;

        div {
          margin-block-end: 4px;

          span {
            background-color: colors.$white;
            display: inline-block;
            padding: 0 8px;
          }
        }
      }
    }

    img {
      block-size: 100%;
      inline-size: 100%;
      object-fit: cover;
      object-position: top center;
      position: relative;
      z-index: 10;

      @include media-breakpoint-down(sm) {
        block-size: 100vh;
      }
    }
  }
}
