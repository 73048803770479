@use "breakpoints";
@use "colors";

.single-module {
  margin-block-end: 12px;
  padding: 4rem 0;
  position: relative;

  @media (min-width: breakpoints.$lg) {
    margin-block-end: 8rem;
    padding: 0;
  }

  &__card {
    img {
      inline-size: 100%;
    }
  }
}

.editorial-title {
  color: colors.$white;
  padding: 0 0 48px;

  @media (min-width: breakpoints.$lg) {
    padding: 0;
  }
}
