@use "colors";
@use "typography";
@import "custom";

.footer {
  background-color: colors.$white;
  user-select: none;

  .footer-inner {
    padding: 80px 0 !important;

    @include media-breakpoint-down(md) {
      max-inline-size: 100% !important;
    }

    @include media-breakpoint-up(lg) {
      padding: 90px 0 !important;
    }
  }

  section {
    .headline-container {
      overflow: hidden;
      position: relative;

      .title {
        @include typography.large-headline;

        color: colors.$black;
        padding-inline-start: 12px;

        @include media-breakpoint-down(md) {
          inline-size: 455px;
        }

        @include media-breakpoint-down(sm) {
          inline-size: 285px;
        }
      }

      @media only screen and (width >= 650px) {
        .headline-hider {
          background-color: colors.$white;
          color: transparent;
          inset: 0;
          position: absolute;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    .cta-container {
      display: inline-block;
      overflow: hidden;
      padding: 8px;
      position: relative;

      a {
        background-color: colors.$black;
        color: colors.$white;
        display: inline-block;
        text-decoration: none;

        .find-a-location {
          display: inline-block;
          padding: 2px 5px;
          position: relative;

          .find-a-location-inner {
            @include typography.large-headline;

            display: flex;
            overflow-wrap: anywhere;

            @include media-breakpoint-down(md) {
              min-block-size: 72px;
            }

            @include media-breakpoint-down(sm) {
              min-block-size: 42px;
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                .arrow-wrapper {
                  margin-inline-start: 30px;
                  transition: all ease-in-out 300ms;
                }
              }
            }
          }
        }

        .arrow-wrapper {
          align-self: center;
          display: flex;
          justify-content: center;
          margin-inline-start: 10px;
          transition: all ease-in-out 300ms;

          svg {
            block-size: auto;
            inline-size: 65px;

            @include media-breakpoint-down(md) {
              inline-size: 50px;
            }

            @include media-breakpoint-down(sm) {
              inline-size: 32px;
            }
          }
        }
      }

      @media only screen and (width >= 650px) {
        .cta-hider {
          background-color: colors.$white;
          color: transparent;
          inset: 0;
          position: absolute;
          transition: all 0.75s ease-in-out;
        }
      }
    }

    .findalocation-wrapper {
      padding-block-start: 8rem;

      @include media-breakpoint-up(lg) {
        padding-block-start: 7.5rem;
      }
    }
  }
}

.footer[data-in-view="true"] {
  section {
    .headline-container {
      .headline-hider {
        inset-inline-start: 100%;
        transition: all 0.5s ease-in-out;
      }
    }

    .cta-container {
      .cta-hider {
        inset-inline-start: 100%;
        transition: all 0.75s ease-in-out;
        transition-delay: 2s;
      }
    }
  }
}
