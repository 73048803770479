@import "mixins";
@import "custom";
@import "variables";

.callout {
  background: $black;
  overflow: hidden;
  padding: 4rem 0;

  @include media-breakpoint-up(lg) {
    padding: 0;
  }

  &--is-theme-light {
    background: $white;
  }

  &__editorialTitle {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 105px 76px 10px 130px !important;

    @include media-breakpoint-down(md) {
      padding: 40px 24px 10px 35px !important;
    }

    &-account {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 80px 76px 10px 95px !important;

      @include media-breakpoint-down(md) {
        padding: 40px 24px 10px 35px !important;
      }

      @include media-breakpoint-up(xl) {
        padding: 40px 24px 10px 135px !important;
      }
    }
  }

  &__imageContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    max-block-size: 580px;
    position: relative;
  }
}

.editorial-title {
  color: $black;

  @include media-breakpoint-down(sm) {
    padding: 0 24px;
  }
}
