@import "mixins";
@import "custom";

.gallery {
  &-play-toggle {
    background-color: $black;
    block-size: 40px;
    border: none;
    color: $white;
    float: left;
    inline-size: 40px;
    inset-block-start: -6px;
    margin-inline-end: 10px;
    outline: none !important;
    padding: 0;
    position: relative;

    @include media-breakpoint-down(md) {
      inset-block-start: -10px;
      inset-inline-start: auto;
      margin-inline-end: 30px;
      position: relative;
    }

    @include media-breakpoint-down(sm) {
      inset-inline-start: 0;
      position: relative;
    }

    svg {
      margin: 0;
    }

    &-wrap {
      position: relative;
    }
  }
}
