@import "mixins";
@import "custom";

.editorial-accordion {
  background-color: #000;
  color: #fff;
  min-block-size: 100vh;
  overflow: hidden;
  padding-block: 140px 40px;
  position: relative;

  &-container-override {
    @include media-breakpoint-down(sm) {
      padding-inline: 0 !important;
    }
  }

  &-left {
    position: relative;
    z-index: 14;

    @include media-breakpoint-down(md) {
      padding: 0 48px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 24px;
    }
  }

  &-right {
    position: relative;
    z-index: 14;

    @include media-breakpoint-down(sm) {
      padding-inline: 0 !important;
    }
  }

  &-backgrounds {
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 0.7;
    overflow: hidden;
    position: absolute;
    z-index: 5;

    img {
      block-size: 100%;
      inline-size: 100%;
      object-fit: cover;
      position: absolute;
      transform: scale(1.01);
    }
  }

  &-list {
    list-style: none;
    padding: 0;

    &-item {
      border-block-end: 1px solid $gray;
      cursor: pointer;
      display: flex;
      outline: none;
      padding: 40px 24px;
      position: relative;
      transition: all 300ms ease-in-out;
      z-index: 11;

      @include media-breakpoint-down(md) {
        display: block;
        padding-inline: 38px;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: 24px;
      }

      &:last-of-type {
        border-block-end: 1px solid transparent;
      }

      &:hover,
      &:active,
      &:focus,
      &-active {
        background-color: $white;
        color: $black;
      }

      a {
        // @include text-button-states-black-border;
        display: inline-block;
        font-size: 14px;
        margin: 20px 0 0;
        outline: none;

        &:focus {
          border-block-end: 1px solid $black;
        }
      }

      &-expander {
        block-size: 0;
        overflow: hidden;
        position: relative;
      }

      &-label {
        inline-size: 30%;

        @include media-breakpoint-down(sm) {
          color: $gray-light;
          margin: 0 0 10px;
        }
      }

      &-title-desc {
        inline-size: 70%;

        @include media-breakpoint-down(sm) {
          inline-size: 100%;
        }
      }

      &-title {
        font-size: 20px;
      }

      &-description {
        color: #999;
        font-size: 14px;
        inline-size: 100%;
        overflow: hidden;
      }

      // &-link {

      // }
    }
  }
}

.editorial-title {
  .header {
    font-size: 60px;
    max-inline-size: 80%;
    position: relative;
  }

  .sub-copy {
    font-size: 20px;
    letter-spacing: normal;
    line-height: 1.5;
    margin: 30px 0;

    p {
      font-size: 20px;
    }

    @include media-breakpoint-down(md) {
      font-size: 18px;
      max-inline-size: 100%;
    }
  }

  .button {
    border: 1px solid $white;
    display: inline-block;
    font-size: 14px;
    margin-block-start: 3em;
    padding: 0.6em 1.8em;

    @include primary-button-black-states;
  }

  .link-list {
    list-style: none;
    margin: 40px 0 0;
    padding: 0;

    li {
      font-size: 14px;
      margin: 20px 0;
    }
  }
}
