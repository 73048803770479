@import "custom";
@import "variables";

@mixin headline-styles {
  h1 {
    margin-block-end: 15px;

    @include media-breakpoint-down(md) {
      font-size: 60px;
    }
  }
}

.module-editorial-full-width {
  padding: 90px 0;

  .non-full-width {
    max-inline-size: 60%;

    @include media-breakpoint-down(md) {
      max-inline-size: 85%;
    }
  }

  .full-width {
    max-inline-size: 100%;
  }

  &.text-large {
    h1 {
      font-size: 96px !important;

      @include media-breakpoint-down(md) {
        font-size: 76px !important;
      }
    }
  }

  &.text-medium {
    h1 {
      font-size: 66px !important;

      @include media-breakpoint-down(md) {
        font-size: 46px !important;
      }
    }
  }

  &.text-small {
    h1 {
      font-size: 46px !important;

      @include media-breakpoint-down(md) {
        font-size: 36px !important;
      }
    }
  }

  &.text-left {
    text-align: start;

    h1 {
      text-align: start;
    }

    div {
      margin-inline-end: auto;
    }
  }

  &.text-right {
    text-align: end;

    h1 {
      text-align: end;
    }

    div {
      margin-inline-start: auto;
    }
  }

  &.text-center {
    text-align: center;

    h1 {
      text-align: center;
    }

    div {
      margin-inline: auto;
    }
  }

  &.black-bg {
    background-color: #000;
    color: #fff;

    @include headline-styles;
  }

  &.white-bg {
    background-color: #fff;
    color: #000;

    @include headline-styles;
  }

  .mobile-logo {
    display: none;
  }

  :global .btn {
    font-size: 1.6rem;
  }

  @include media-breakpoint-down(md) {
    .mobile-logo {
      display: flex;
    }
  }
}

.top-spacer {
  block-size: 34px;
}
