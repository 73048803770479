@use "breakpoints";
@use "colors";

// https://www.figma.com/file/EfT2zPdkI7yuTzetvKGnIz/Web-Library?node-id=1185%3A5171&t=6hFzSqQsW7JxapOQ-0

@mixin xl-headline {
  font-size: 4.8rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: breakpoints.$lg) {
    font-size: 8rem;
  }
}

@mixin large-headline {
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: breakpoints.$lg) {
    font-size: 6.4rem;
  }
}

@mixin medium-headline {
  font-size: 3.2rem;
  font-weight: 600;
  letter-spacing: -0.02em;
  line-height: 1;
  text-transform: uppercase;

  @media (min-width: breakpoints.$lg) {
    font-size: 4.2rem;
  }
}

@mixin small-headline {
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.1;

  @media (min-width: breakpoints.$lg) {
    font-size: 3.2rem;
  }
}

@mixin subhead-semi-bold {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.2;

  @media (min-width: breakpoints.$lg) {
    font-size: 2.4rem;
  }
}

@mixin subhead-regular {
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.2;

  @media (min-width: breakpoints.$lg) {
    font-size: 2.4rem;
  }
}

@mixin body {
  font-size: 1.6rem; // 16px
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3; // 21px

  @media (min-width: breakpoints.$lg) {
    font-size: 1.8rem;
    line-height: 1.4;
  }
}

@mixin body-medium {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3;

  @media (min-width: breakpoints.$lg) {
    font-size: 1.6rem;
    line-height: 1.4;
  }
}

@mixin body-small {
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.3;

  @media (min-width: breakpoints.$lg) {
    line-height: 1.4;
  }
}

@mixin body-extra-small {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.4;

  @media (min-width: breakpoints.$lg) {
    line-height: 1.5;
  }
}

@mixin cta {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.1;

  @media (min-width: breakpoints.$lg) {
    font-size: 1.8rem;
  }
}

@mixin cta-small {
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.2;

  @media (min-width: breakpoints.$lg) {
    line-height: 1.1;
  }
}

@mixin eyebrow {
  font-family: "Messina Sans Mono", monospace;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.06em;
  line-height: 1;
  text-transform: uppercase;
}
