@import "mixins";
@import "custom";

.gallery {
  &-progress-bar {
    block-size: 100%;
    inline-size: 100%;
    position: relative;

    > span {
      background-color: $gray-progress-bar;
      block-size: 6px;
      display: block;
      inline-size: 0;
    }
  }
}
