@import "mixins";
@import "custom";
@import "variables";

$duration: 1000ms;

.enter {
  inline-size: 100%;
  position: absolute !important;
  transform: scale(1.1);
  z-index: 13;
}

.enter-active {
  position: absolute;
  transform: scale(1);
}

.enter-done {
  position: relative;
  z-index: 12;
}

.exit {
  z-index: 11;
}

.exit-active {
  position: absolute !important;
}
