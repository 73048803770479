@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.img-opacity {
  opacity: 0.5;
}

.card-description {
  block-size: 295px;
  border-radius: 0;
  color: colors.$off-black;
  inline-size: 404px;
  inset-block-end: 328px;
  position: relative;

  @include media-breakpoint-down(lg) {
    inset-block-end: 328px;
  }

  @media only screen and (device-width >= 1920px) {
    inline-size: 472px;
    inset-block-end: 368px;
  }
}

.card-block-margin {
  margin: 31px 24px;
}

.cta-link {
  block-size: 24px;
  inline-size: 72px;
  line-height: 1.71;
  text-decoration: underline;
}

.module-editorial-grid-with-description-headline {
  @include typography.small-headline;

  color: colors.$black;
  margin-block-end: 8px;

  @include media-breakpoint-down(md) {
    margin-block-end: -194px;
  }
}

.module-editorial-grid-with-description-tag {
  @include typography.eyebrow;

  background-color: colors.$black;
  color: colors.$white;
  padding: 4px 8px;
}

.module-editorial-grid-with-description-tertiary-copy {
  margin-block-start: 8px;
}
