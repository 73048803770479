@use "breakpoints";
@use "colors";
@use "typography";

/**********************************************************************
 * https://www.figma.com/file/EfT2zPdkI7yuTzetvKGnIz/Web-Library?node-id=1166%3A4365&t=tFTfUZwz6L11ux3n-4
 */

$theme-primary-dark: "primary-dark";
$theme-primary-light: "primary-light";
$theme-secondary-dark: "secondary-dark";
$theme-secondary-light: "secondary-light";
$width-no-max: "no-max-width";
$width-primary: "primary-width";
$width-secondary: "secondary-width";
$width-wide: "wide-width";

/********************************************************************   */

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _theme-primary-light {
  background-color: colors.$white;
  border-color: colors.$white;
  color: colors.$black;

  &:focus,
  &:focus-visible,
  &:hover {
    background-color: colors.$light-gray;
    border-color: colors.$light-gray;
    color: colors.$black;
  }

  &[disabled],
  &[aria-disabled="true"] {
    background-color: colors.$gray;
    border-color: colors.$gray;
    color: colors.$off-black;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _theme-primary-dark {
  background-color: colors.$black;
  border-color: colors.$black;
  color: colors.$white;

  &:focus,
  &:focus-visible,
  &:hover {
    background-color: colors.$off-black;
    border-color: colors.$off-black;
    color: colors.$white;
  }

  &[disabled],
  &[aria-disabled="true"] {
    background-color: colors.$light-gray;
    border-color: colors.$light-gray;
    color: colors.$dark-gray;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _theme-secondary-light {
  background-color: transparent;
  border-color: colors.$white;
  color: colors.$white;

  &:focus,
  &:focus-visible,
  &:hover {
    background-color: colors.$dark-gray;
    border-color: colors.$white;
    color: colors.$white;
  }

  &[disabled],
  &[aria-disabled="true"] {
    background-color: transparent;
    border-color: colors.$gray;
    color: colors.$gray;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _theme-secondary-dark {
  background-color: transparent;
  border-color: colors.$black;
  color: colors.$black;

  &:focus,
  &:focus-visible,
  &:hover {
    background-color: colors.$off-black;
    border-color: colors.$black;
    color: colors.$white;
  }

  &[disabled],
  &[aria-disabled="true"] {
    background-color: transparent;
    border-color: colors.$gray;
    color: colors.$dark-gray;
  }
}

/********************************************************************   */

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _width-no-max {
  inline-size: 100%;
  max-inline-size: none;

  @media (min-width: breakpoints.$lg) {
    max-inline-size: none;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _width-primary {
  inline-size: 100%;
  max-inline-size: 18.7rem;

  @media (min-width: breakpoints.$lg) {
    max-inline-size: 26.8rem;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _width-secondary {
  inline-size: max-content;
  padding-inline: 5rem;

  @media (min-width: breakpoints.$lg) {
    padding-inline: 6rem;
  }
}

/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _width-wide {
  inline-size: 100%;
  max-inline-size: none;

  @media (min-width: breakpoints.$lg) {
    max-inline-size: 26.8rem;
  }
}

/********************************************************************   */

@mixin button($theme, $width) {
  @include typography.cta;

  align-items: center;
  block-size: 4.8rem;
  border: 0.1rem solid transparent;
  display: flex;
  justify-content: center;
  padding: 0;
  transition: all 0.2s ease-in;

  &:hover {
    cursor: pointer;
  }

  &[disabled],
  &[aria-disabled="true"] {
    cursor: not-allowed;
  }

  @media (min-width: breakpoints.$lg) {
    block-size: 6.4rem;
  }

  @if $theme == $theme-primary-dark {
    @include _theme-primary-dark;
  }

  @if $theme == $theme-primary-light {
    @include _theme-primary-light;
  }

  @if $theme == $theme-secondary-dark {
    @include _theme-secondary-dark;
  }

  @if $theme == $theme-secondary-light {
    @include _theme-secondary-light;
  }

  @if $width == $width-no-max {
    @include _width-no-max;
  }

  @if $width == $width-primary {
    @include _width-primary;
  }

  @if $width == $width-secondary {
    @include _width-secondary;
  }

  @if $width == $width-wide {
    @include _width-wide;
  }
}

/********************************************************************   */

@mixin right-caret-button {
  @include typography.cta-small;

  background-color: transparent;
  border: none;
  margin-block-end: 1.6rem;
  padding: 0;

  &::after {
    block-size: 0.8rem;
    border-block-start: 0.1rem solid colors.$black;
    border-inline-end: 0.1rem solid colors.$black;
    content: "";
    display: inline-block;
    inline-size: 0.8rem;
    margin-inline-start: 0.8rem;
    transform: rotate(45deg);
  }
}

/********************************************************************   */

@mixin x-button {
  block-size: 1.7rem;
  display: block;
  inline-size: 1.7rem;
  overflow: hidden;
  padding-block-start: 1.7rem;
  position: relative;

  &::before,
  &::after {
    background-color: colors.$white;
    block-size: 0.2rem;
    content: "";
    display: block;
    inline-size: 1.8rem;
    inset-block-start: 0.8rem;
    opacity: 1;
    position: absolute;
    transition: opacity 0.2s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:focus,
  &:focus-visible,
  &:hover {
    &::before,
    &::after {
      opacity: 0.2;
    }
  }
}
