@import "variables";

.visible {
  opacity: 1;
  transition: 0.5s;
}

.invisible {
  opacity: 0;
  transition: 0.5s;
}

.explore-a-club {
  min-block-size: 135px;
  position: relative;

  .club-name {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.4;
  }

  .explore-more-clubs {
    font-size: 20px;
    line-height: 1.4;
    padding-inline-start: 35px;
  }

  .club-name,
  .explore-more-clubs {
    &:hover {
      color: $gray-60;
    }
  }

  .explore-header {
    color: $white;
    display: block;
    font-size: 14px;
    inset-block-start: -35px;
    margin-block-end: 15px;

    &--black {
      color: black;
    }
  }

  .white-theme {
    background: $white;
    border-radius: 6px;
    color: $black;
    display: flex;
    padding: 20px 0;

    .explore-more-clubs {
      max-inline-size: 90%;
    }

    &--withPadding {
      padding: 20px 35px;
    }
  }

  .white-theme-padding {
    padding: 20px 35px;
  }

  .transparent-theme {
    color: $white;
    display: flex;

    .explore-more-clubs {
      max-inline-size: 80%;
    }
  }

  .address {
    color: $gray-60;
    display: inline-block;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.71;
    padding-block-start: 5px;
  }

  .explore-club-first {
    block-size: 100%;
    border-inline-end: solid $gray-60 1px;
    display: flex;
    flex-direction: column;
    inline-size: 165px;
    justify-content: space-between;
    padding-inline-end: 35px;
  }

  .explore-club {
    block-size: 100%;
    border-inline-end: solid $gray-60 1px;
    display: flex;
    flex-direction: column;
    inline-size: 165px;
    justify-content: space-between;
    padding-inline: 35px;
  }
}

.explore-a-club-mobile {
  background-color: $white;
  display: flex;
  inline-size: 100%;
  justify-content: space-between;
  margin-block-start: 25px;

  .explore-left {
    background-color: $white;
    padding-inline: 24px;

    .explore-left-inner {
      font-size: 14px;
      padding: 12px 0;
    }

    .explore-header {
      border-block-end: solid $white-f3 1px;
    }

    .explore-club {
      color: $gray;
    }
  }

  .explore-right {
    align-items: center;
    background-color: $white-f3;
    display: flex;
    justify-content: center;
  }
}
