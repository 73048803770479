@import "mixins";
@import "custom";

.sticky-header {
  position: relative;

  &-row {
    display: flex;
    justify-content: space-between;
    position: relative;

    @include media-breakpoint-down(md) {
      display: block;
    }

    &-item {
      position: relative;
      text-align: center;

      @include media-breakpoint-down(md) {
        margin: 5px 0 8px;
      }

      img {
        max-block-size: 30px;
      }

      &:first-of-type {
        img {
          max-block-size: 18px;
        }
      }

      &:nth-of-type(2) {
        inset-inline-start: 50%;
        position: absolute;
        transform: translateX(-50%);

        @include media-breakpoint-down(md) {
          inset-inline-start: auto;
          position: relative;
          transform: none;
        }
      }
    }
  }

  &-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 36px 20px 27px;
    position: relative;
    transition: all 300ms;
    z-index: 999;

    @include media-breakpoint-down(md) {
      padding: 12px 20px 5px;
    }

    &-fixed {
      background-color: $black;
      inline-size: 100%;
      inset-block-start: 88px;
      inset-inline-end: 0;
      position: fixed;
    }
  }
}

@media (width <= 1024px) {
  .sticky-header {
    &-inner {
      &-fixed {
        inset-block-start: 55px;
      }
    }
  }
}
