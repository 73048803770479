/* Colors! */
$bg-color: #000;
$black: #000;
$black-t40: rgb(0 0 0 / 40%);
$black-t10: rgb(0 0 0 / 10%);
$white: #fff;
$white-fc: #fcfcfc;
$white-f3: #f3f3f3;
$gray: #757575;
$gray-seperator: #242424;
$gray-dark: #333;
$gray-border: #666;
$gray-legal-text: #737373;
$gray-background: #666;
$gray-progress-bar: #808080;
$gray-light: #999;
$lighter-gray: #cbcbcb;
$dim-gray: #737373;
$gray-80: #ccc;
$gray-44: #707070;
$gray-84: #848484;
$gray-a5: #a5a5a5;
$gray-d0: #d0d0d0;
$gray-7b: #7b7b7b;
$gray-a9: #a9a9a9;
$gray-8c: #8c8c8c;
$gray-bf: #bfbfbf;
$gray-bb: #bbb;
$gray-cf: #cfcfcf;
$gray-ef: #efefef;
$gray-f9: #f9f9f9;
$gray-divider: #d8d8d8;
$gray-snow: #fafafa;
$gray-60: #999;
$red-error: #e00;
$common-question-border-grey: #cacaca;
$red: #f00;
$banner-gray: #2c2e34;
$bg-textarea: #1a1a1a;
$bg-equifit: #080808;
$bg-header: #0a0a0a;
$gray-6e: #6e6e6e;
$gray-67: #676767;
$gray-9a: #9a9a9a;
$white-f4: #f4f4f4;
$bar-color1: #3860c8;
$bar-color2: #2c3ed6;
$bar-color3: #201be4;
$bar-color4: #7012a0;
$bar-color5: #970d7f;
$bar-color6: #bf085d;
$green-color: #008e75;
$gray-7c: #7c878e;
$gray-border: rgb(255 255 255 / 50%);
$black-input-bg: rgb(255 255 255 / 0%);
$proof-of-vaccination-blue: #0056b3;
