@use "sass:map";
@import "mixins";
@import "custom";

.on-demand-landscape {
  overflow: hidden;
  padding: 40px 0;
  position: relative;

  &-container {
    margin: 0 auto;
    max-inline-size: map.get($container-max-widths, "xl");

    @include media-breakpoint-down(lg) {
      max-inline-size: map.get($container-max-widths, "lg");
    }

    @include media-breakpoint-down(md) {
      max-inline-size: map.get($container-max-widths, "md");
      padding: 0 0 60px;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 0 0 0 24px;
  }

  &-carousel {
    display: block;
    position: relative;

    &-slide {
      margin: 0;

      // max-width: 900px;
      // width: 100%;
      margin-inline-end: 10px;
      padding: 0;
      position: relative;

      &-last {
        opacity: 0 !important;
      }
    }
  }

  &-editorial-title {
    @include media-breakpoint-down(md) {
      margin: 0 0 45px;
    }
  }

  &-controls {
    inset-block-end: 30%;
    inset-inline-end: 15%;
    margin: 2px;
    position: absolute;

    @include media-breakpoint-down(md) {
      display: none;
    }

    &-left {
      position: relative;
    }

    &-right {
      margin: 0;
      position: relative;
    }

    svg {
      margin: 0;
      padding: 0;
    }

    button {
      background-color: $white;
      border: 1px solid transparent;
      box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 15%);
      margin: 2px 6px 2px 2px;
      outline: none;
      padding: 1px;

      &:last-of-type {
        margin: 2px;
      }

      &:active {
        border: 1px solid $black;
      }

      &:disabled {
        border: 1px solid transparent;

        svg {
          opacity: 0.4;
        }
      }
    }
  }
}

.editorial-title {
  color: $white;
  padding: 0;

  @include media-breakpoint-down(md) {
    padding: 0 0 24px;
  }

  @include media-breakpoint-down(sm) {
    max-inline-size: none !important;
    padding: 0 0 24px;
  }
}
