@use "breakpoints";
@use "colors";

@function percentage-column-width($columns, $gap) {
  @return calc((100% / $columns) - (($gap / $columns) * ($columns - 1)));
}

/**
 * https://www.figma.com/file/EfT2zPdkI7yuTzetvKGnIz/Web-Library?type=design&node-id=1158-6806&mode=dev
 */
@mixin module-content {
  --layout-gutter: 2.4rem;

  display: flex;
  flex-direction: column;
  inline-size: 100%;
  margin-inline: auto;
  max-inline-size: breakpoints.$xl;
  padding-inline: var(--layout-gutter);
  position: relative;

  @media (width >= breakpoints.$md) {
    --layout-gutter: 4.8rem;
  }

  @media (width >= breakpoints.$xl) {
    --layout-gutter: 12.4rem;
  }
}

@mixin content-grid {
  --layout-gap: 0.8rem;

  display: grid;
  gap: var(--layout-gap);
  grid-template-columns: repeat(12, minmax(0, 1fr));

  @media (width >= breakpoints.$md) {
    --layout-gap: 1.6rem;

    grid-template-columns: repeat(16, minmax(0, 1fr));
  }

  @media (min-width: breakpoints.$lg) {
    --layout-gap: 2.4rem;
  }
}

@mixin content-subgrid($columns: 1) {
  display: grid;
  gap: var(--layout-gap);
  grid-template-columns: repeat($columns, minmax(0, 1fr));

  // grid-template-columns: subgrid;
  // grid-template-rows: subgrid;
}

@mixin module-content-grid {
  @include module-content;
  @include content-grid;

  > * {
    grid-column-end: span 12;
  }

  @media (min-width: breakpoints.$sm) {
    > * {
      grid-column-end: span 6;
    }
  }

  @media (min-width: breakpoints.$md) {
    > * {
      grid-column-end: span 4;
    }
  }
}

@mixin equinox-logo {
  background-color: colors.$white;
  block-size: 15px;
  display: block;
  inline-size: 115px;
  mask-image: url("https://assets.cdn-equinox.com/images/equinox-logo.svg");
  mask-position: 0% 50%;
  mask-repeat: no-repeat;
  overflow: hidden;
  padding-block-start: 15px;
}
