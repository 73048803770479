// https://www.figma.com/file/EfT2zPdkI7yuTzetvKGnIz/Web-Library?node-id=1184%3A4768&t=6hFzSqQsW7JxapOQ-0

// Primary colors
$black: rgb(0 0 0 / 100%); // #000000
$white: rgb(255 255 255 / 100%); // #FFFFFF

// Secondary colors
$charcoal-black: rgba(21, 21, 21, 100%); // #151515
$off-black: rgb(51 51 51 / 100%); // #333333
$dark-gray: rgb(90 90 90 / 100%); // #5A5A5A
$medium-gray: rgb(122 122 122 / 100%); // #7A7A7A
$gray: rgb(170 170 170 / 100%); // #AAAAAA
$light-gray: rgb(237 237 237 / 100%); // #EDEDED
$semi-transparent-white: rgba(255, 255, 255, 15%); // #7A7A7A
$mist-white: rgba(255, 255, 255, 7%); // #FFFFFF

// Indicator colors
$error-red: rgb(224 50 44 / 100%); // #E0322C
$progress-highlight: rgb(0 193 159 / 100%); // #00C19F;

$lavender: rgb(149, 132, 255); // #9584FF
