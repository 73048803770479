.mobile-store-links {
  inline-size: 100%;
  margin-block-start: 40px;

  &-container {
    display: flex;

    .link-container {
      a {
        img {
          inline-size: 160px;
        }

        .apple-store-img {
          block-size: 53px;
          object-fit: cover;
        }

        .google-play-store-img {
          block-size: 48px;
        }
      }
    }
  }
}
