@use "breakpoints";
@use "colors";
@use "layout";

.editorial-article-scroller {
  color: colors.$white;
  position: relative;

  @media (width >= breakpoints.$xs) {
    padding-block: 30px;
  }

  @media (width >= breakpoints.$sm) {
    padding-block: 80px;
  }
}

.section {
  @include layout.module-content-grid;

  padding-block: 5rem;
  position: relative;

  + .section {
    margin-block-start: 25vh;
  }
}

.photo {
  grid-column: 1 / span 12;
  grid-row: 3 / span 1;

  picture {
    aspect-ratio: 1 / 1;
    display: block;
  }

  @media (width >= breakpoints.$md) {
    grid-column: 1 / span 10;
    grid-row: 1 / span 1;

    picture {
      inset-block-start: var(--global-header-height);
      position: sticky;
    }
  }
}

.content {
  display: contents;
  word-break: break-word;

  > * {
    grid-column: 1 / span 12;
  }

  p {
    font-size: 20px;
    line-height: 1.5em;
  }

  @media (width >= breakpoints.$md) {
    display: block;
    grid-column: 12 / span 5;
    grid-row: 1 / span 1;
    transform: translateY(25vh);
  }
}

.eye-brow {
  background-color: colors.$white;
  color: colors.$black;
  display: inline-block;
  grid-row: 1 / span 1;
  margin-block-end: 30px;
  margin-inline-end: auto;
  padding: 2px 10px;
  text-transform: uppercase;
}

.secondary-headline {
  font-size: 56px;
  grid-row: 2 / span 1;

  @media (width >= breakpoints.$sm) {
    font-size: 62px;
  }
}

.description {
  grid-row: 4 / span 1;
  margin: 50px 0 0;
}

.spotify-link {
  grid-row: 4 / span 1;
  margin: 80px 0 0;
}

.cta {
  grid-row: 4 / span 1;
  margin: 50px 0 0;
}
