@use "colors";
@use "typography";
@import "custom";

.three-column-expander {
  outline: none;
  position: relative;
  transition: all 200ms ease-in-out;

  &-active {
    .arrow-container {
      opacity: 0;
    }
  }

  .arrow-container {
    padding-inline: 0;
    position: relative;
    transition: opacity 300ms;
  }

  .content-container {
    border-block-end: 1px solid colors.$medium-gray;
    cursor: pointer;
    padding: 40px 24px;
    position: relative;
    z-index: 11;

    @include media-breakpoint-up(lg) {
      &:focus,
      &:hover {
        background-color: colors.$white;
        color: colors.$black;

        .title {
          color: colors.$black !important;
        }
      }
    }

    @include media-breakpoint-down(md) {
      inline-size: calc(100% - 96px);
      margin: 0 48px;
    }

    @include media-breakpoint-down(sm) {
      inline-size: calc(100% - 48px);
      margin: 0 24px;
    }

    .category {
      @include typography.body-small;

      color: colors.$white;

      @include media-breakpoint-down(md) {
        padding-inline: 6px;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: 0;
      }

      &.active {
        color: colors.$medium-gray;
      }
    }

    .title {
      @include typography.subhead-semi-bold;

      color: colors.$white;
      padding-inline: 6px;
      transition: color 300ms ease-in-out;

      @include media-breakpoint-down(sm) {
        padding-inline: 0;
      }

      &.active {
        color: colors.$black;
      }
    }

    .featured-tag {
      @include typography.eyebrow;

      color: colors.$black;
      padding-inline: 6px;

      @include media-breakpoint-down(sm) {
        margin-block-end: 3px;
        padding-inline: 0;
      }

      .featured-tag-box {
        background-color: colors.$white;
        margin: 5px 0;
        padding: 1px 6px;
        transition: all 300ms ease-in-out;

        &.active {
          background-color: colors.$black;
          color: colors.$white;
        }
      }
    }

    .description {
      @include typography.body-small;

      block-size: 0;
      color: colors.$dark-gray;
      margin-block-start: 15px;
      opacity: 0;
      overflow: hidden;
      padding-inline: 6px;

      @include media-breakpoint-down(sm) {
        margin: 10px 0;
      }

      @include media-breakpoint-down(sm) {
        padding-inline: 0;
      }
    }
  }

  &:last-of-type {
    .content-container {
      border-block-end: none;
    }
  }

  .row-background {
    background-color: colors.$white;
    block-size: 0;
    inline-size: 100%;
    inset: 0 0 auto;
    position: absolute;
    transition: height 300ms ease-in-out;
    z-index: 10;

    &.row-active {
      block-size: 100%;
      transition: height 500ms ease-in-out;
    }
  }
}
