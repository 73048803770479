@import "custom";

.bottom-page-cta {
  background-color: $white;

  .footer-inner {
    padding: 90px 0 !important;

    @include media-breakpoint-down(md) {
      max-inline-size: 100% !important;
    }
  }

  section {
    .headline-container {
      overflow: hidden;
      position: relative;

      .title {
        color: $black;
        font-size: 104px;
        font-weight: 500;
        inline-size: 455px;
        line-height: 1.1;
        padding-inline-start: 12px;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
          font-size: 72px;
          inline-size: 350px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 42px;
          inline-size: 200px;
        }
      }

      @media only screen and (width >= 650px) {
        .headline-hider {
          background-color: white;
          color: transparent;
          inset: 0;
          position: absolute;
          transition: all 0.5s ease-in-out;

          &.headline-reveal {
            inset-inline-start: 100%;
            transition: all 0.5s ease-in-out;
          }
        }
      }
    }

    .cta-container {
      background-color: #000;
      display: inline-block;
      margin-inline-start: 8px;
      overflow: hidden;
      position: relative;

      a {
        color: #fff;
        font-size: 80px;
        font-weight: 200;
        line-height: 1.1;
        text-decoration: none;

        .find-a-location {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: center;
          padding: 2px 5px;
          position: relative;
          text-transform: uppercase;

          .find-a-location-inner {
            block-size: 104px;
            display: flex;
            font-size: 104px;
            letter-spacing: 2px;

            @include media-breakpoint-down(md) {
              block-size: 72px;
              font-size: 72px;
            }

            @include media-breakpoint-down(sm) {
              block-size: 42px;
              font-size: 42px;
            }

            @include media-breakpoint-up(lg) {
              &:hover {
                .arrow-wrapper {
                  margin-inline-start: 30px;
                  transition: all ease-in-out 300ms;
                }
              }
            }
          }
        }

        .arrow-wrapper {
          align-self: center;
          display: flex;
          justify-content: center;
          margin-inline-start: 20px;
          transition: all ease-in-out 300ms;

          svg {
            block-size: auto;
            inline-size: 65px;

            @include media-breakpoint-down(md) {
              inline-size: 50px;
            }

            @include media-breakpoint-down(sm) {
              inline-size: 32px;
            }
          }
        }
      }

      @media only screen and (width >= 650px) {
        .cta-hider {
          background-color: white;
          color: transparent;
          inset: 0;
          position: absolute;
          transition: all 0.75s ease-in-out;

          &.cta-reveal {
            inset-inline-start: 100%;
            transition: all 0.75s ease-in-out;
            transition-delay: 2s;
          }
        }
      }
    }

    .find-a-location-wrapper {
      padding-block-start: 75px;
    }
  }
}
