@use "colors";
@use "typography";
@import "custom";

.module-editorial-grid2-column {
  &__card {
    @include typography.body;

    color: colors.$gray;
    margin-block-end: 30px;

    &:first-of-type {
      padding-inline-start: 0;

      @include media-breakpoint-down(md) {
        margin-block-end: 0;
        padding-inline-start: 12px;
      }
    }

    img {
      inline-size: 100%;
    }
  }

  &__media {
    margin-block: 20px 24px;
    overflow: hidden;
    padding-block-end: 133.33%;
  }

  &__img {
    object-fit: cover;
  }

  &__title {
    @include typography.small-headline;

    color: colors.$white;
    margin-block-end: 8px;

    @include media-breakpoint-down(md) {
      margin-block-end: 10px;
    }
  }

  &__subtitle {
    @include typography.small-headline;

    color: colors.$dark-gray;
  }

  &__subcopy {
    color: colors.$white;
  }

  &__tag {
    @include typography.eyebrow;

    background-color: colors.$white;
    color: colors.$black;
    padding: 4px 8px;
  }

  &__ctaBtn {
    @include typography.cta;

    color: colors.$white;
    margin-block-start: 10px;

    svg {
      margin-inline-start: 25px;
    }
  }

  &__internalCtaBtn {
    @include typography.cta;

    background: none;
    border: none;
    color: colors.$white;
    margin-block-start: 10px;
    padding: 0;
    text-align: start;

    svg {
      margin-inline-start: 25px;
    }

    &:hover {
      color: inherit;
    }
  }

  &__ctaContainer {
    display: flex;
    flex-direction: column;
  }

  &__tertiaryCopy {
    margin-block-start: 8px;
  }
}
