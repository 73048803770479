@use "breakpoints";
@import "accessibility";

.intent-option {
  cursor: pointer;
  font-size: 12px;
  outline: none;
  text-align: center;
  text-transform: uppercase;

  @media (width >= breakpoints.$md) {
    font-size: 16px;
  }

  @include primary-button-black-states;
}
