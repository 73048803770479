@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.editorial-single-image {
  padding: 4em 0;
  position: relative;
  user-select: none;

  @include media-breakpoint-down(md) {
    margin: 0;
    padding: 4rem 0;
  }

  &-bottom-text {
    inline-size: 100%;
  }

  &-container {
    block-size: 100%;
    color: colors.$white;
    inline-size: 100%;
    margin: 0 auto;
    overflow: hidden;
    text-align: start;

    &-text {
      display: flex;
      margin-block-start: 2em;
    }

    &-image {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    img {
      block-size: auto;
      inline-size: 100%;
    }

    h2 {
      margin: 0 0 24px;
    }
  }

  &-mobile {
    display: none;
    margin: 0 auto 2em;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}
