@use "breakpoints";
@use "buttons";
@use "colors";
@use "typography";

.button {
  @include buttons.button(
    buttons.$theme-secondary-light,
    buttons.$width-secondary
  );

  @media (min-width: breakpoints.$sm) {
    margin-block-start: 3em;
  }

  @media (min-width: breakpoints.$lg) {
    &[data-is-long="true"] {
      padding-inline: 20px;
    }
  }
}

.editorial-title {
  color: colors.$white;
  padding: 0 24px;
  user-select: none;

  img {
    max-inline-size: 100%;
  }

  @media (min-width: breakpoints.$sm) {
    padding: 0;
  }
}

.image-wrap {
  margin: 30px 0;

  img {
    max-inline-size: 100%;
  }
}

.label {
  @include typography.eyebrow;
}

.header {
  @include typography.medium-headline;

  margin-block-end: 0;
  padding-block-end: 2.4rem;
  position: relative;

  @media (min-width: breakpoints.$lg) {
    margin-block-end: 3.2rem;
    padding-block-end: 0;
  }
}

.sub-copy {
  padding-block-end: 2.4rem;

  @media (min-width: breakpoints.$lg) {
    padding-block-end: 0;
  }
}

.white-border {
  border: 1px solid colors.$white;
}

.black-border {
  border: 1px solid colors.$black;
}

.link-list {
  color: colors.$white;
  list-style: none;
  margin: 40px 0 0;
  padding: 0;

  li {
    margin: 20px 0;
  }
}
