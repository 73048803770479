@use "breakpoints";
@use "layout";

.module {
  @include layout.module-content;

  overflow: hidden;
  padding-block-end: 4rem;

  @media (width >= breakpoints.$sm) {
    [data-is="EditorialTitle"] {
      grid-column-end: span 12;
    }
  }

  @media (width >= breakpoints.$lg) {
    @include layout.content-grid;

    overflow: initial;

    [data-is="EditorialTitle"] {
      grid-column-end: span 6;
      inset-block-start: calc(var(--global-header-height) + 2.4rem);
      margin-block-end: auto;
      position: sticky;
    }
  }
}

.cards {
  --columns: 1.25;
  --gap: 2.4rem;

  display: flex;
  flex-flow: row nowrap;
  gap: var(--gap);
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  outline: none;
  overflow-x: scroll;
  padding: 0;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    cursor: grab;
  }

  > * {
    flex: 0 0 layout.percentage-column-width(var(--columns), var(--gap));
    inline-size: 100%;
    padding-block: 1.6rem;
    scroll-snap-align: start;
    transition: background-color 0.3s ease;
    user-select: none;

    &:first-of-type {
      padding-inline-start: 0;
    }

    @media (width >= breakpoints.$md) {
      --columns: 2;

      &:nth-child(even) {
        transform: translateY(25%);
      }
    }
  }

  @media (width >= breakpoints.$sm) {
    --columns: 2.5;

    grid-column-end: span 12;
  }

  @media (width >= breakpoints.$md) {
    flex-wrap: wrap;
    grid-column-end: span 16;

    &:hover {
      cursor: initial;
    }
  }

  @media (width >= breakpoints.$lg) {
    grid-column-end: span 10;
  }
}
