@import "mixins";
@import "custom";

.media-card {
  color: $white;
  position: relative;

  &-media-wrap {
    inline-size: calc(100vw / 2.35);
    margin: 0 0 38px;
    max-inline-size: 720px;
    overflow: hidden;

    img {
      inline-size: 100%;
      max-inline-size: none;
    }

    @include media-breakpoint-down(md) {
      inline-size: calc(100vw / 2);
    }

    @include media-breakpoint-down(sm) {
      inline-size: calc(100vw - 80px);
    }
  }

  &-lower {
    display: flex;
    inline-size: calc(100vw / 2.35);
    position: relative;

    @include media-breakpoint-down(md) {
      inline-size: calc(100vw / 2);
    }

    @include media-breakpoint-down(sm) {
      inline-size: calc(100vw - 80px);
    }

    &-play-pause-toggle {
      margin: 0 40px 0 0;

      @include media-breakpoint-down(sm) {
        margin: 0 30px 0 0;
      }

      button {
        border: 4px double transparent;
        margin: 0;
        outline: none;
        padding: 0;

        &:focus {
          border: 4px double $black;
        }
      }
    }
  }

  &-media-image-el {
    position: relative;
  }

  &-media-video-wrap {
    position: relative;
  }

  &-headline-el {
    font-size: 20px;
  }

  &-sub-copy-wrap {
    font-size: 16px;
  }
}

.video-player {
  display: inline-block;
  margin: 0;
  overflow: hidden;
  position: relative;
}
