@use "breakpoints";
@use "buttons";
@use "colors";
@use "typography";
@import "custom";

.upcoming-classes {
  background-color: colors.$black;
  color: colors.$white;
  min-block-size: 100vh;
  overflow: hidden;
  padding-block: 140px 40px;
  position: relative;
}

.upcoming-classes-top {
  @include media-breakpoint-down(md) {
    margin: 0 48px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 24px;
  }
}

.background-image-container {
  block-size: 100%;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  opacity: 0.5;
  position: absolute;

  .background-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    block-size: 100%;
    inline-size: 100%;
    inset-block-start: 0;
    inset-inline-start: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 0.5s;

    &[aria-hidden="false"] {
      opacity: 0.9;
    }

    &-desktop {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &-mobile {
      display: none;

      @include media-breakpoint-down(md) {
        display: block;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  max-inline-size: 119.2rem;
  position: relative;

  @media (min-width: breakpoints.$lg) {
    flex-direction: row;
  }
}

.class-list {
  @media (min-width: breakpoints.$lg) {
    margin-inline-start: 12.2%;
    opacity: 0;
    transform: translateY(250px);
  }
}

.class-header {
  display: contents;

  @media (min-width: breakpoints.$lg) {
    display: block;
    flex: 0 0 27.8%;
    opacity: 0;
    transform: translateY(250px);
  }
}

.classes-headline {
  @include typography.medium-headline;

  max-inline-size: 22.5rem;
  white-space: pre-wrap;

  @media (min-width: breakpoints.$lg) {
    max-inline-size: 25rem;
  }
}

.classes-description {
  color: inherit;
  margin: 24px 60px 56px 0;
  max-inline-size: 325px;

  @media (min-width: breakpoints.$sm) {
    margin-inline: 0;
  }
}

.classes-ctas {
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;
  order: 3;
  padding: 2.4rem;

  @media (min-width: breakpoints.$lg) {
    order: unset;
    padding: 0;
  }
}

.view-class-schedule,
.download-class-schedule {
  @include buttons.button(
    buttons.$theme-secondary-light,
    buttons.$width-secondary
  );

  inline-size: 100%;

  & + .download-class-schedule {
    margin-block-start: 1.6rem;
  }

  @media (min-width: breakpoints.$lg) {
    padding-inline: 20px;
  }
}
